import React, { useEffect, useState, useCallback } from "react";
import "../Styles/Homepage.css";
import { useMediaQuery } from "react-responsive";
import { DataComp } from "../Component/DataComp";
import "react-loading-skeleton/dist/skeleton.css";
import { API_ROOT, webPath } from "../apiConfig";

// Generate srcSet for responsive images
const generateSrcSet = (imagePath, widths) => {
  return widths
    .map((width) => `${imagePath}?width=${width} ${width}w`)
    .join(", ");
};

const Eventpage = () => {
  const [activeKey, setActiveKey] = useState("tab1");
  const [newData, setNewData] = useState(
    []
  );
  const [visibleCount, setVisibleCount] = useState(3); // Initial count for visible items
  const [loading, setLoading] = useState(false);

  // Fetch data function with localStorage caching
  const fetchData = async (url, setter, key) => {
    try {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      setter(data);
    //   const currentData = localStorage.getItem(key);

    //   if (JSON.stringify(data) !== currentData) {
    //     // localStorage.setItem(key, JSON.stringify(data));
    //   }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllData = () => {
      fetchData(`${API_ROOT}/api/post/homelatest`, setNewData, "newData");
    };

    fetchAllData();

    // const intervalId = setInterval(fetchAllData, 10000);

    // return () => clearInterval(intervalId);
  }, []);

  // Load more items by increasing visible count
  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dataCompWidth = isLargeScreen ? 500 : 300;

  return (
    <div
      className="container container-max mt-3 mb-2 main-page"
      style={{ overflow: "hidden" }}
    >
      <div className="row">
        <div className="col-12">
          <h1 className="fw-bold borderB py-1 h5">Events</h1>
        </div>

        <div
          className=" col-12 container borderR"
          style={{ overflow: "hidden", minHeight: "700px" }}
        >
          <div style={{ width: "100%" }}>
            <h4>Upcoming Events</h4>
          </div>
          <div className="mainSecondBox mt-3" style={{ overflow: "hidden" }}>
            <div className="row">
              {Array.isArray(newData) &&
                newData.slice(0, visibleCount).map((item) => (
                  <div key={item.id} className="col-md-4 col-12">
                    {" "}
                    {/* 3 per row on medium+ screens */}
                    <a
                      className="text-decoration-none text-black"
                      href={`${item?.cat_slug}/${item?.post_name}`}
                    >
                      <DataComp
                        src={`${webPath}${item?.banner_img}?width=${dataCompWidth}`}
                        srcSet={generateSrcSet(
                          `${webPath}${item?.banner_img}`,
                          [150, 300, 500, 700, 1000, 1200]
                        )}
                        alt={item?.post_name}
                        h2Title={item?.post_title}
                        p_Desc={item.post_content}
                        post_author={item?.post_author}
                        post_date={item?.post_date}
                        withZoom={true}
                        fetchpriority="low"
                      />
                    </a>
                    <button
                      style={{
                        borderRadius: "8px", // Rounded corners
                        backgroundColor: "black", // Black background
                        color: "white", // White text
                        border: "none", // Remove default button border
                        padding: "8px 16px", // Add padding for better appearance
                        cursor: "pointer", // Pointer cursor on hover
                      }}
                    >
                      Register
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {/* Load More button */}
          {visibleCount < newData.length && (
            <div className="d-flex justify-content-center mt-3">
              <button onClick={handleLoadMore} className="btn btn-dark w-75">
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Eventpage;
